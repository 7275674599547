import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import SmallReceipeBanner from '../components/small-receipe-banner'
import FindusBanner from '../components/find-us-banner'
import Seo from '../components/seo'
import * as styles from './foire-aux-questions.module.css'
import chevronDown from '../../static/images/chevron-down.svg'

const AccordionItem = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={
      <>
        {header}
        <img className={styles.chevron} src={chevronDown} alt="Chevron Down" />
      </>
    }
    className={styles.item}
    buttonProps={{
      className: ({ isEnter }) =>
        `${styles.itemBtn} ${isEnter && styles.itemBtnExpanded}`
    }}
    contentProps={{ className: styles.itemContent }}
    panelProps={{ className: styles.itemPanel }}
  />
);

class FaqIndex extends React.Component {
  render() {
    const faqs = get(this, 'props.data.allContentfulFaq.nodes')

    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { gatsbyImage, description } = node.data.target
        return (
           <GatsbyImage
              image={getImage(gatsbyImage)}
              alt={description}
           />
         )
        },
      },
    };

    return (
      <>
        <div className={styles.faqPage}>
            <div
              className={styles.faqImage}
            />
          <div className={styles.faq}>
            <Seo title="Foire aux questions" />
            <h1>Foire aux questions</h1>
            <Accordion transition transitionTimeout={200}>
              {faqs.map((faq, index) => {
                return (
                  <AccordionItem header={faq.question} key={index}>
                    {faq.response?.raw && renderRichText(faq.response, options)}
                  </AccordionItem>
                )
              })}
            </Accordion>
          </div>
        </div>
        <FindusBanner />
        <SmallReceipeBanner />
      </>

    )
  }
}

export default FaqIndex

export const pageQuery = graphql`
  query FaqIndexQuery {
    allContentfulFaq {
      nodes {
        question
        response {
          raw
        }
      }
    }
  }
`
